import backendRequest from './backendRequest'
import generateActions from './generateActions'
import standardGet from './standardGet'
import standardPost from './standardPost'

/**
 * Action identifiers.
 * @type {Object}
 */
export const PROFILE_GET_ACTIONS = generateActions('PROFILE_GET_')

export const PROFILE_PASSWORD_ACTIONS = generateActions('PROFILE_PASSWORD_')

export const SET_NOTIFICATION_ACTIONS = generateActions('PROFILE_NOTIFICATION_')

export const GET_NOTIFICATION_SETTINGS_ACTIONS = generateActions(
	'GET_NOTIFICATION_SETTINGS_'
)

export const FREELANCER_PERFORMANCE_ACTIONS = generateActions(
	'FREELANCER_PERFORMANCE_ACTIONS'
)

export const DELETE_ACCOUNT_ACTIONS = generateActions('DELETE_ACCOUNT_ACTIONS')

export const DOWNLOAD_PROFILE_ACTIONS = generateActions(
	'DOWNLOAD_PROFILE_ACTIONS'
)

export const DOWNLOAD_CHATS_ACTIONS = generateActions('DOWNLOAD_CHATS_ACTIONS')
/**
 * Requests notification settings from the back end.
 */
export const getNotificationSettings = () => {
	return standardGet({
		url: `users/notifications`,
		actions: GET_NOTIFICATION_SETTINGS_ACTIONS,
	})
}

/**
 * function to reset User Password in Profile view
 * @param {[type]} email [description]
 */
export const requestPasswordReset = (email) => {
	return standardPost({
		url: 'users/password-reset',
		data: { email: email },
		actions: PROFILE_PASSWORD_ACTIONS,
	})
}

export const setNotification = (notification, status, email) => {
	return standardPost({
		url: 'users/set-notification',
		data: { notification: notification, status: status },
		actions: SET_NOTIFICATION_ACTIONS,
	})
}

export const deleteAccount = () => {
	return (dispatch, getState) => {
		return backendRequest('users/delete-account', {
			method: 'POST',
			headers: new Headers({
				'Content-Type': 'application/json; charset=utf-8',
				'skovse-token': getState().authentication.token,
			}),
			body: '',
		})
			.then((response) => {
				dispatch({
					type: DELETE_ACCOUNT_ACTIONS.SUCCESS,
				})
			})
			.catch(() =>
				dispatch({
					type: DELETE_ACCOUNT_ACTIONS.FAILURE,
				})
			)
	}
}

export const downloadProfile = () => {
	return standardGet({
		url: 'users/download-profile',
		actions: DOWNLOAD_PROFILE_ACTIONS,
	})
}

export const downloadChats = () => {
	return standardGet({
		url: 'users/download-chats',
		actions: DOWNLOAD_CHATS_ACTIONS,
	})
}
