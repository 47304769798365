import { apiFetch } from 'api'

const logError = async (error) => {
	console.error(error)
	try {
		await apiFetch('/error-report', {
			method: 'POST',
			body: {
				error: {
					message: error.message,
					stack: error.stack,
				},
			},
		})
	} catch (err) {
		console.error(err)
	}
}

export default logError
