import { action, computed } from 'easy-peasy'

const chatsModel = {
	chatListDb: {},
	chatList: computed((state) => Object.values(state.chatListDb)),
	setChatInfo: action((state, chatInfo) => {
		state.chatListDb[chatInfo.chatKey] = chatInfo
	}),
	setChatList: action((state, chatList) => {
		if (Array.isArray(chatList)) {
			state.chatListDb = {}
			chatList.forEach(
				(chatInfo) => (state.chatListDb[chatInfo.chatKey] = chatInfo)
			)
		}
	}),
	clear: action((state) => {
		state.chatListDb = {}
	}),
}

export default chatsModel
