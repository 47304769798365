import backendRequest from './backendRequest'

/**
 * Performs a common-case get from the backend server.
 * @param  {Object} config
 * @return {function}
 */
function standardGet(config) {
	return (dispatch, getState) => {
		dispatch({ type: config.actions.SENDING })
		backendRequest(config.url, {
			method: 'GET',
			headers: new Headers({
				'Content-Type':
					config.type || 'application/json; charset=utf-8',
				'skovse-token': getState().authentication.token,
			}),
		})
			.then((response) =>
				dispatch({
					type: config.actions.SUCCESS,
					config,
					response,
				})
			)
			.catch((error) => dispatch({ type: config.actions.FAILURE, error }))
	}
}

export default standardGet
