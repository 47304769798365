import { action } from 'easy-peasy'

const uiModel = {
	userWasDeleted: false,
	userWasActivated: false,
	setUserWasDeleted: action((state, userWasDeleted) => {
		state.userWasDeleted = userWasDeleted
	}),
	setUserWasActivated: action((state, userWasActivated) => {
		state.userWasActivated = userWasActivated
	}),
	clear: action((state) => {
		state.userWasDeleted = false
		state.userWasActivated = false
	}),
}

export default uiModel
