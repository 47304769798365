import backendRequest from './backendRequest'
import generateActions from './generateActions'

/**
 * Action identifiers.
 * @type {Object}
 */
export const RESET_PASSWORD_ACTIONS = generateActions('RESET_PASSWORD_')

export const REST_PASSWORD_TOKEN_CHECK_ACTIONS = generateActions(
	'REST_PASSWORD_TOKEN_CHECK_'
)

/**
 * function to reset User Password in backend service
 * @param {[type]} email [description]
 */
export const resetPassword = (token, password) => {
	const send = () => {
		return { type: RESET_PASSWORD_ACTIONS.SENDING }
	}
	const succeed = () => {
		return { type: RESET_PASSWORD_ACTIONS.SUCCESS }
	}
	const fail = (response) => {
		return {
			type: RESET_PASSWORD_ACTIONS.FAILURE,
			reason: response,
		}
	}

	return (dispatch) => {
		dispatch(send())
		backendRequest('users/password', {
			method: 'POST',
			body: JSON.stringify({ password: password }),
			headers: new Headers({
				'Content-Type': 'application/json; charset=utf-8',
				'skovse-token': token,
			}),
		})
			.then(() => {
				dispatch(succeed())
			})
			.catch((error) => {
				dispatch(fail(error))
			})
	}
}

export const checkPasswordResetToken = (token) => {
	const send = () => {
		return { type: REST_PASSWORD_TOKEN_CHECK_ACTIONS.SENDING }
	}
	const succeed = () => {
		return { type: REST_PASSWORD_TOKEN_CHECK_ACTIONS.SUCCESS }
	}
	const fail = (response) => {
		return {
			type: REST_PASSWORD_TOKEN_CHECK_ACTIONS.FAILURE,
			reason: response,
		}
	}

	return (dispatch) => {
		dispatch(send())
		backendRequest('users/validate-password-reset-token', {
			method: 'GET',
			headers: new Headers({
				'Content-Type': 'application/json; charset=utf-8',
				'skovse-token': token,
			}),
		})
			.then(() => {
				dispatch(succeed())
			})
			.catch((error) => {
				dispatch(fail(error))
			})
	}
}
