
const GOALS = {
  REGISTRATION: 1,
  LOGIN: 2,
}

/**
 * Tracks the event and sends it to matomo analytics
 */
const trackEvent = (action, options = {}) => {
  const {
    category = window.location.pathname,
    name,
    value,
  } = options
  window._paq.push(['trackEvent', category, action, name, value])
}

/**
 * Tracks the goal and sends it to matomo analytics
 */
const trackGoal = (idGoal) => {
  window._paq.push(['trackGoal', idGoal])
}

export { trackEvent, trackGoal, GOALS }
