import { action, computed } from 'easy-peasy'

const organisationProjectsModel = {
	projectsDb: {},
	projects: computed((state) => Object.values(state.projectsDb)),
	setProject: action((state, project) => {
		state.projectsDb[project._key] = project
	}),
	setProjects: action((state, projects) => {
		if (Array.isArray(projects)) {
			state.projectsDb = {}
			projects.forEach(
				(project) => (state.projectsDb[project._key] = project)
			)
		} else {
			state.projectsDb = {}
		}
	}),
	clear: action((state) => {
		state.projectsDb = {}
	}),
}

export default organisationProjectsModel
