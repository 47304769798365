import React from 'react'

import logError from 'logError'

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = { hasError: false }
	}

	static getDerivedStateFromError(error) {
		return { hasError: true }
	}

	componentDidCatch(error, info) {
		const doLog = async () => {
			try {
				logError(error)
			} finally {
			}
		}
		doLog()
	}

	render() {
		if (this.state.hasError) {
			return (
				<div>
					<p style={{ color: 'darkred' }}>
						<strong>Error encountered</strong>
					</p>
					<p>Try refreshing the page.</p>
					<p>
						Let us know if we can help:{' '}
						<a href='mailto:support@skilloverse.com'>
							support@skilloverse.com
						</a>
						.
					</p>
				</div>
			)
		}

		return this.props.children
	}
}
