import {
	LOGIN_ACTIONS,
	CHECK_EMAIL_AVAILABLE_ACTIONS,
	TOKEN_REFRESH,
} from '../actions/authentication'
import {
	RESET_PASSWORD_ACTIONS,
	REST_PASSWORD_TOKEN_CHECK_ACTIONS,
} from '../actions/resetPassword'
import { USER_UPDATE_ACTIONS } from '../actions/user'
import { DELETE_ACCOUNT_ACTIONS } from '../actions/profile'

import logError from 'logError'

// See if the browser has saved the user state already.
const savedStateRaw = window.localStorage.getItem('user-state')
let savedState,
	savedStateTemp = null

if (savedStateRaw) {
	try {
		savedStateTemp = JSON.parse(savedStateRaw)
		if (
			Object.keys(savedStateTemp).length !== 3 ||
			typeof savedStateTemp.loggedIn !== 'boolean' ||
			typeof savedStateTemp.currentUser !== 'object' ||
			typeof savedStateTemp.token !== 'string' ||
			Object.keys(savedStateTemp.currentUser).length !== 3 ||
			!savedStateTemp.currentUser.userKey.match(/^[0-9a-z-]+$/) ||
			['freelancer', 'business'].indexOf(
				savedStateTemp.currentUser.userType
			) < 0 ||
			typeof savedStateTemp.currentUser.hasExtendedProfile !== 'boolean'
		) {
			throw new Error('Invalid local state')
		} else if (
			new Date(
				JSON.parse(
					atob(
						savedStateTemp.token.substring(
							savedStateTemp.token.indexOf('.') + 1,
							savedStateTemp.token.lastIndexOf('.')
						)
					)
				).exp * 1000
			) <= new Date()
		) {
			throw new Error('Session expired')
		}
		savedState = savedStateTemp
	} catch (err) {
		logError(err)

		// Local storage is invalid, so clean it up.
		window.localStorage.removeItem('user-state')
	}
}

/**
 * The state of the app when no one is logged in.
 * @type {Object}
 */
const emptyState = {
	currentUser: null,
	loggedIn: false,
	loginPending: false,
	loginFailed: false,
	loginFailureReason: '',
	emailAvailabilityLoading: false,
	emailAvailabilityStatus: null,
}

/**
 * The initial authentication state.
 * @type {Object}
 */
const initialState = savedState || emptyState

/**
 * Generates a logged in state
 * Side effect: state is saved in local storage so the session can be resumed.
 * @param {String} email
 * @return {Object} Authentication state.
 */
const generateLoggedInState = (authData) => {
	const userState = {
		loggedIn: true,
		currentUser: authData.user || authData.currentUser,
		token: authData.token,
		hasExtendedProfile: authData.hasExtendedProfile,
	}
	window.localStorage.setItem('user-state', JSON.stringify(userState))
	return Object.assign({}, userState, { loginPending: false })
}

/**
 * Generates the state with token updated.
 */
const generateUpdatedTokenState = (state, token) => {
	const { currentUser, hasExtendedProfile, loggedIn } = state
	const newState = generateLoggedInState({
		loggedIn,
		currentUser,
		token,
		hasExtendedProfile,
	})
	return Object.assign({}, state, newState)
}

const updateHasExtendedProfileInStorage = (state) => {
	window.localStorage.setItem(
		'user-state',
		JSON.stringify({
			loggedIn: state.loggedIn,
			currentUser: Object.assign({}, state.currentUser, {
				hasExtendedProfile: true,
			}),
			token: state.token,
		})
	)
}

function emptyLoginState() {
	window.localStorage.removeItem('user-state')
}

/**
 * Redux reducer for the application. Creates a new state given the current
 * state and an action being performed.
 * @param {Object} state - The current state of the app.
 * @param {Object} action - The action being taken.
 */
const authenticationReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_ACTIONS.SENDING:
			return Object.assign({}, state, { loginPending: true })
		case LOGIN_ACTIONS.SUCCESS:
			return Object.assign({}, state, generateLoggedInState(action.data))
		case LOGIN_ACTIONS.FAILURE:
			emptyLoginState()
			return Object.assign({}, emptyState, {
				loginFailed: true,
				loginFailureReason: action.reason,
			})
		case 'LOGOUT':
			emptyLoginState()
			return Object.assign({}, emptyState)
		case REST_PASSWORD_TOKEN_CHECK_ACTIONS.SUCCESS:
			return Object.assign({}, state, { resetPasswordTokenValid: true })
		case REST_PASSWORD_TOKEN_CHECK_ACTIONS.FAILURE:
			return Object.assign({}, state, { resetPasswordTokenValid: false })
		case RESET_PASSWORD_ACTIONS.SUCCESS:
			return Object.assign({}, state, { passwordReseted: true })
		case RESET_PASSWORD_ACTIONS.FAILURE:
			return Object.assign({}, state, { passwordReseted: false })
		case CHECK_EMAIL_AVAILABLE_ACTIONS.SENDING:
			return Object.assign({}, state, { emailAvailabilityLoading: true })
		case CHECK_EMAIL_AVAILABLE_ACTIONS.SUCCESS:
			return Object.assign({}, state, {
				emailAvailabilityStatus: action.data.isAvailable,
				emailAvailabilityLoading: false,
			})
		case CHECK_EMAIL_AVAILABLE_ACTIONS.FAILURE:
			return Object.assign({}, state, { emailAvailabilityLoading: false })
		case USER_UPDATE_ACTIONS.SUCCESS:
			updateHasExtendedProfileInStorage(state)
			return Object.assign({}, state, {
				currentUser: Object.assign({}, state.currentUser, {
					hasExtendedProfile: true,
				}),
			})
		case DELETE_ACCOUNT_ACTIONS.SUCCESS:
			window.localStorage.removeItem('user-state')
			return Object.assign({}, emptyState, { accountDeleted: true })
		case TOKEN_REFRESH.SUCCESS:
			return generateUpdatedTokenState(state, action.token)
		default:
			return state
	}
}

export default authenticationReducer
