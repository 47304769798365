import { action } from 'easy-peasy'

const invitationsModel = {
	invitationsCount: 0,

	setInvitationsCount: action((state, count) => {
		state.invitationsCount = count
	}),

	clear: action((state) => {
		state.invitationsCount = 0
	}),
}

export default invitationsModel
