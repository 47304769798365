import backendRequest from './backendRequest'

/**
 * Using the provided dispatch and getState functions, execute the post.
 * @param  {function} dispatch
 * @param  {function} getState
 * @return {Promise}
 */
function doThePost(config, dispatch, getState) {
	dispatch({
		type: config.actions.SENDING,
		data: config.data,
		actionData: config.actionData,
	})
	return backendRequest(config.url, {
		method: 'POST',
		headers: new Headers({
			'Content-Type': 'application/json; charset=utf-8',
			'skovse-token': getState().authentication.token,
		}),
		body: JSON.stringify(config.data),
	})
		.then((response) =>
			dispatch({
				type: config.actions.SUCCESS,
				data: config.data,
				response: response,
				actionData: config.actionData,
			})
		)
		.catch(() =>
			dispatch({
				type: config.actions.FAILURE,
				data: config.data,
				actionData: config.actionData,
			})
		)
}

/**
 * Performs a common-case post to the backend server.
 * @todo dynamically determining the source of dispatch and getState feels very
 * very wrong. There must be a better way to handle this.
 * @param  {Object} config
 * @return {function}
 */
function standardPost(config, existingDispatch, existingGetState) {
	if (existingDispatch && existingGetState) {
		return doThePost(config, existingDispatch, existingGetState)
	}
	return (dispatch, getState) => {
		return doThePost(config, dispatch, getState)
	}
}

export default standardPost
