import { action } from 'easy-peasy'

const subscriptionModel = {
	subscription: null,

	setSubscription: action((state, subscription) => {
		state.subscription = subscription
	}),

	clear: action((state) => {
		state.subscription = null
	}),
}

export default subscriptionModel
