import generateActions from './generateActions'
import standardGet from './standardGet'
import standardPost from './standardPost'

/**
 * Action identifiers for fetching a user object.
 * @type {Object}
 */
export const USER_GET_ACTIONS = generateActions('USER_GET_')

/**
 * Action identifiers for updating an attribute.
 * @type {Object}
 */
export const USER_UPDATE_ATTRIBUTE = generateActions('USER_UPDATE_ATTRIBUTE_')

/**
 * Action identifiers for updating multiple user fields at once.
 * @type {Object}
 */
export const USER_UPDATE_ACTIONS = generateActions('USER_UPDATE_')

/**
 * Action identifiers for getting the billing address.
 * @type {Object}
 */
export const GET_BILLING_ADDRESS = generateActions('GET_BILLING_ADDRESS_')

/**
 * Action identifiers for updating the billing address.
 * @type {Object}
 */
export const UPDATE_BILLING_ADDRESS = generateActions('UPDATE_BILLING_ADDRESS_')

/**
 * Action identifiers for getting invoices.
 * @type {Object}
 */
export const GET_INVOICES = generateActions('GET_INVOICES_')

/**
 * Action identifiers for downloading an invoice.
 * @type {Object}
 */
export const DOWNLOAD_INVOICE = generateActions('DOWNLOAD_INVOICE_')

/**
 * Action identifiers for sending an invoice.
 */
export const SEND_INVOICE = generateActions('SEND_INVOICE_')

/**
 * Action identifiers for setting the consent status for the user of a user's
 * location.
 */
export const USER_SET_LOCATION_USE_CONSENT = generateActions(
	'USER_SET_LOCATION_USE_CONSENT_'
)

/**
 * Gets a user's data from backend
 * @param  {String} userKey The key identifying the user.
 * @return {function} A dispatchable function.
 */
export const getUser = () =>
	standardGet({
		url: `users`,
		actions: USER_GET_ACTIONS,
	})

/**
 * Gets the billing address of the current user.
 */
export const getBillingAddress = () =>
	standardGet({
		url: `users/billing-address`,
		actions: GET_BILLING_ADDRESS,
	})

/**
 * Updates the billing address of the current user.
 * @param  {Object} billingAddress The new billing address.
 * @return {function} A dispatchable function.
 */
export const updateBillingAddress = (billingAddress) =>
	standardPost({
		url: `users/billing-address`,
		actions: UPDATE_BILLING_ADDRESS,
		data: billingAddress,
	})