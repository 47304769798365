import backendRequest from './backendRequest'
import generateActions from './generateActions'
//import standardGet from './standardGet'
import standardPost from './standardPost'

import { trackGoal, GOALS } from '../services/analytics'
import logError from 'logError'

/**
 * Action indentifiers for the user registration process.
 * @type {Object}
 */
export const REGISTRATION_ACTIONS = generateActions('USER_REGISTRATION_')

/**
 * Action identifiers for the user login process.
 * @type {Object}
 */
export const LOGIN_ACTIONS = generateActions('USER_LOGIN_')

/**
 * Action identifiers when checking if email is availble.
 */
export const CHECK_EMAIL_AVAILABLE_ACTIONS = generateActions(
	'USER_CHECK_EMAIL_AVAILABLE_'
)

/**
 * Action identifiers for the user forgotPassword process.
 * @type {Object}
 */
export const USER_FORGOT_PASSWORD_ACTION = generateActions(
	'USER_FORGOT_PASSWORD_'
)

/**
 * Action identifiers when refreshing the user token.
 */
export const TOKEN_REFRESH = generateActions('TOKEN_REFRESH_')

/**
 * Used like a process id for the interval that keeps the token updated
 * in the background.
 */
let tokenRefreshIntervalId = null

/**
 * Function that runs at a set interval to check if the token needs to be
 * refreshed or not. If the token needs refreshing, a call to the backend will
 * be triggered to fetch a new token.
 */
export const keepTokenUpdated = () => {
	if (tokenRefreshIntervalId) {
		return null
	}
	return (dispatch, getState) => {
		const tenMinutesInMilliseconds = 36000000
		const seventyTwoHoursInSeconds = 259200
		const backendUrl = process.env.REACT_APP_BACKEND_BASE_URL
		const refreshTokenUrl = backendUrl + 'users/refresh-token'
		tokenRefreshIntervalId = setInterval(() => {
			try {
				const token = getState().authentication.token
				if (!token) {
					return // Not logged in, no action needed.
				}

				// Check if the token will expire soon.
				const tokenExpiry = JSON.parse(
					atob(
						token.substring(
							token.indexOf('.') + 1,
							token.lastIndexOf('.')
						)
					)
				).exp
				const nowInSeconds = Math.floor(Date.now() / 1000)
				if (nowInSeconds + seventyTwoHoursInSeconds > tokenExpiry) {
					// Get a new token using the current token to authenticate
					fetch(refreshTokenUrl, {
						headers: {
							'skovse-token': token,
						},
					})
						.then((res) => res.json())
						.then((refreshResponse) => {
							if (refreshResponse.tokenStillValid) {
								return
							}
							dispatch({
								type: TOKEN_REFRESH.SUCCESS,
								token: refreshResponse.token,
							})
						})
						.catch((err) => {
							logError(err)
							return { type: TOKEN_REFRESH.FAILURE }
						})
				}
			} catch (err) {
				logError(err)
			}
		}, tenMinutesInMilliseconds)
	}
}

/**
 * Indiciates whether subsequent requests are needed after the current request
 * completes. This is used to throttle requests of the same time to only be done
 * one at a time.
 */
const emailIndicators = {
	lastCheckedEmail: '',
	lastRequestedEmail: '',
	isLoading: false,
}

const sendEmailCheck = () => ({ type: CHECK_EMAIL_AVAILABLE_ACTIONS.SENDING })
const succeedEmailCheck = (data) => ({
	type: CHECK_EMAIL_AVAILABLE_ACTIONS.SUCCESS,
	data: data,
})
const failEmailCheck = (response) => ({
	type: CHECK_EMAIL_AVAILABLE_ACTIONS.FAILURE,
	reason: response,
})

const sendCheckEmailRequest = (dispatch, emailAddress) => {
	emailIndicators.isLoading = true
	dispatch(sendEmailCheck())
	backendRequest('users/email-availability', {
		method: 'POST',
		body: JSON.stringify({ email: emailAddress }),
		headers: new Headers({
			'Content-Type': 'application/json; charset=utf-8',
		}),
	})
		.then((response) => {
			emailIndicators.lastCheckedEmail = emailAddress
			setTimeout(() => {
				emailIndicators.isLoading = false
				if (
					emailIndicators.lastRequestedEmail !==
					emailIndicators.lastCheckedEmail
				) {
					sendCheckEmailRequest(
						dispatch,
						emailIndicators.lastRequestedEmail
					)
				} else {
					dispatch(succeedEmailCheck(response))
				}
			}, 300)
		})
		.catch((err) => dispatch(failEmailCheck(err.message)))
}

/**
 * Checks if an email address is available.
 * @param  {String} emailAddress
 * @return {function}
 */
export const checkEmailIsAvailable = (emailAddress) => {
	return (dispatch) => {
		emailIndicators.lastRequestedEmail = emailAddress
		if (
			emailIndicators.isLoading !== true &&
			emailIndicators.lastCheckedEmail !== emailAddress
		) {
			sendCheckEmailRequest(dispatch, emailAddress)
		}
	}
}

/**
 * Attempts to log the user in by using the backend to validate the provided
 * login credentials.
 * @param {Object} loginFieldValues Contains email and password properties.
 * @return {function} - Function to initiate registation actions.
 */
export const attemptLogin = (loginFieldValues) => {
	const send = () => ({ type: LOGIN_ACTIONS.SENDING })
	const succeed = (data) => ({ type: LOGIN_ACTIONS.SUCCESS, data: data })
	const fail = (response) => ({
		type: LOGIN_ACTIONS.FAILURE,
		reason: response,
	})

	return (dispatch) => {
		dispatch(send())
		backendRequest('users/login', {
			method: 'POST',
			body: JSON.stringify(loginFieldValues),
			headers: new Headers({
				'Content-Type': 'application/json; charset=utf-8',
			}),
		})
			.then((response) => {
				trackGoal(GOALS.LOGIN)
				dispatch(succeed(response))
			})
			.catch((error) => {
				dispatch(fail(error.message))
			})
	}
}

/**
 * Action to show that user registration post request has been sent.
 * @return {function}
 */
export const postRegistration = () => {
	return {
		type: REGISTRATION_ACTIONS.SENDING,
	}
}

/**
 * Action to show that user registration saved correctly.
 * @return {function}
 */
export const showRegistrationSuccess = (response) => {
	return {
		type: REGISTRATION_ACTIONS.SUCCESS,
		response: response,
	}
}

/**
 * Action to show that the registration login failed to save.
 * @return {function}
 */
export const showRegistrationFailure = (reason) => {
	return {
		type: REGISTRATION_ACTIONS.FAILURE,
		reason,
	}
}

/**
 * Trigger action to log a user out.
 * @return {function} - Function to create logout action.
 */
export const logout = () => {
	return {
		type: 'LOGOUT',
	}
}

/**
 * Trigger action to remind user of password
 * @return {function} - Function to remind user of password
 */

export const forgotPassword = (email) =>
	standardPost({
		url: 'users/forgot-password',
		data: { email: email },
		actions: USER_FORGOT_PASSWORD_ACTION,
	})
