import { action } from 'easy-peasy'

const currentUserModel = {
	currentUser: null,
	billingAddress: {
		name: '',
		addressLine1: '',
		addressLine2: '',
		state: '',
		postcode: '',
		country: '',
	},

	setCurrentUser: action((state, user) => {
		state.currentUser = user
	}),

	setBillingAddress: action((state, billingAddress) => {
		state.billingAddress = billingAddress
	}),

	clear: action((state) => {
		state.currentUser = null
		state.billingAddress = {
			name: '',
			addressLine1: '',
			addressLine2: '',
			state: '',
			postcode: '',
			country: '',
		}
	}),
}

export default currentUserModel
