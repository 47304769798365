import React, { FC, lazy, Suspense } from 'react';
import { createStore, persist, reducer, StoreProvider } from 'easy-peasy';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import authenticationReducer from 'reducers/authentication';
import projectsModel from 'redux/project/model';
import currentUserModel from 'redux/currentUser/model';
import subscriptionModel from 'redux/subscription/model';
import invitationsModel from 'redux/invitations/model';
import chatsModel from 'redux/chats/model';
import uiModel from 'redux/ui/model';
import organisationProjectsModel from 'redux/organisationProjects/model';
import ErrorBoundary from 'components/ErrorBoundary';

interface AppBaseProps {}

const storeModel = {
	authentication: reducer(authenticationReducer),
	projects: projectsModel,
	currentUser: persist(currentUserModel),
	subscription: persist(subscriptionModel),
	invitations: persist(invitationsModel),
	chats: persist(chatsModel),
	ui: uiModel,
	organisationProjects: organisationProjectsModel,
};

const store = createStore(storeModel)

const FrontPage = lazy(() => import('routes/FrontPage'))
const LogInPage = lazy(() => import('components/auth/LogIn'))
const SignUp = lazy(() => import('components/auth/SignUp'))
const NotFound = lazy(() => import('components/static/NotFound'))
const AccountActivate = lazy(() => import('components/account-activate/AccountActivate'));
const Chats = lazy(() => import('components/chat/Chats'));
const Home = lazy(() => import('components/home/Home'));
const Invitations = lazy(() => import('components/invitations/Invitations'));
const MeetFreelancers = lazy(() => import('components/meet-freelancers/MeetFreelancers'));
const Profile = lazy(() => import('components/profile/Profile'));
const ProjectEditor = lazy(() => import('components/projects/ProjectEditor'));
const Settings = lazy(() => import('components/settings/Settings'));
const Projects = lazy(() => import('components/projects/Projects'));
const LogOut = lazy(() => import('components/auth/LogOut'));
const PasswordResetRequest = lazy(() => import('components/auth/PasswordResetRequest'));
const PasswordReset = lazy(() => import('components/auth/PasswordReset'));
const ProjectFindFreelancers = lazy(
	() => import('components/project-find-freelancers/ProjectFindFreelancers')
);

const AppBase: FC<AppBaseProps> = (props) => {

	return (
		<StoreProvider store={store}>
			<ErrorBoundary>
				<Router basename='/app'>
					<Suspense fallback={<div>Loading...</div>}>
						<Switch>
							<Route exact path='/' component={FrontPage} />
							<Route path='/home' component={Home} />
							<Route
								exact
								path={['/log-in', '/login']}
								component={LogInPage}
							/>
							<Route
								exact
								path={['/sign-up', '/signup', '/register']}
								component={SignUp}
							/>
							<Route
								exact
								path={['/log-out', '/logout']}
								component={LogOut}
							/>
							<Route
								path='/password-reset/:resetToken'
								component={PasswordReset}
							/>
							<Route
								path='/password-reset'
								component={PasswordResetRequest}
							/>
							<Route
								path='/activate'
								component={AccountActivate}
							/>
							<Route
								path={['/chats/:id', '/chats']}
								component={Chats}
							/>
							<Route
								path={[
									'/invitations/projects/:projectId',
									'/invitations/freelancers/:inviteId',
									'/invitations',
								]}
								component={Invitations}
							/>
							<Route
								path='/meet-freelancers'
								component={MeetFreelancers}
							/>
							<Route path='/profile' component={Profile} />
							<Route
								path='/projects/:id/candidates'
								component={ProjectFindFreelancers}
							/>
							<Route
								path='/projects/new'
								component={ProjectEditor}
							/>
							<Route path='/projects' component={Projects} />
							<Route path='/settings' component={Settings} />
							<Route component={NotFound} />
						</Switch>
					</Suspense>
				</Router>
			</ErrorBoundary>
		</StoreProvider>
	)
};

export default AppBase;
