import { useState, useEffect, useRef } from 'react'

const API_URL =
	process.env.REACT_APP_BACKEND_BASE_URL.slice(-1) === '/'
		? process.env.REACT_APP_BACKEND_BASE_URL.substring(
				0,
				process.env.REACT_APP_BACKEND_BASE_URL.length - 1
		  )
		: process.env.REACT_APP_BACKEND_BASE_URL

const applicationJsonPattern = /^application\/json\b/
const textPlainPattern = /^text\/plain\b/
const applicaitonPdfPattern = /^application\/pdf\b/

const pathToUrl = (path) => {
	let _path = '/'
	if (typeof path === 'string') {
		_path = path.substring(0, 1) === '/' ? path : `/${path.substring(0)}`
	}
	const url = API_URL + _path
	return url
}

const getErrorMessageFromResponse = async (response, defaultErrorMessage) => {
	let errorText = response.statusText
	if (defaultErrorMessage) {
		errorText = defaultErrorMessage
	}
	const contentType = response.headers.get('Content-Type')
	if (contentType && textPlainPattern.test(contentType)) {
		try {
			errorText = await response.text()
		} catch (err) {}
	} else if (contentType && applicationJsonPattern.test(contentType)) {
		try {
			errorText = (await response.json()).error || errorText
		} catch (err) {}
	}
	return errorText
}

const getBodyFromResponse = async (response) => {
	const contentType = response.headers.get('Content-Type')
	if (contentType && applicationJsonPattern.test(contentType)) {
		return await response.json()
	}
	if (contentType && applicaitonPdfPattern.test(contentType)) {
		return await response.blob()
	}
	return await response.text()
}

const filterInit = (inputInit = {}, token) => {
	const _init = Object.assign({}, inputInit)
	const headers = new Headers(_init.headers || {})
	const method = _init.method || 'GET'
	const mode = _init.mode || 'cors'
	if (method === 'POST' || method === 'PUT' || method === 'PATCH') {
		if (!headers.get('Content-Type')) {
			headers.set('Content-Type', 'application/json; charset=utf-8')
		}
		const contentType = headers.get('Content-Type').toLowerCase()
		if (applicationJsonPattern.test(contentType)) {
			_init.body = JSON.stringify(_init.body)
		}
	}
	if (token && !headers.get('skovse-token')) {
		headers.set('skovse-token', token)
	}
	const init = Object.assign({}, _init, { headers, mode })
	return init
}

const getToken = () => {
	const token =
		window.sessionStorage.getItem('sv-auth-token') ||
		window.localStorage.getItem('sv-auth-token') ||
		null
	return token
}

const useApiFetchBase = (
	initialRequest = null,
	initialPayload = null,
	opts = {}
) => {
	const isAuthenticated = opts.isAuthenticated || false
	const [payload, setPayload] = useState(initialPayload)
	const [request, setRequest] = useState(initialRequest)
	const [isLoading, setIsLoading] = useState(false)
	const [isError, setIsError] = useState(false)
	const [isSuccess, setIsSuccess] = useState(false)
	const [isDone, setIsDone] = useState(false)
	const [errorMessage, setErrorMessage] = useState(false)
	const isAuthenticatedRef = useRef(isAuthenticated)
	const tokenRef = useRef(isAuthenticated ? getToken() : null)

	useEffect(() => {
		if (!request) {
			return
		}
		const opts = request.opts || {}
		if (isAuthenticatedRef.current && !tokenRef.current) {
			setErrorMessage("You don't appear to be logged in")
			setIsError(true)
			setIsDone(true)
			setIsLoading(false)
			return
		}

		const url = pathToUrl(request.path)
		const init = filterInit(request.init, tokenRef.current)
		const defaultErrorMessage = opts.defaultErrorMessage

		const fetchPayload = async () => {
			try {
				const result = await fetch(url, init)
				if (!result.ok) {
					const errorText = await getErrorMessageFromResponse(
						result,
						defaultErrorMessage
					)
					throw new Error(errorText)
				}
				const payload = await getBodyFromResponse(result)
				setPayload(payload)
				setIsSuccess(true)
			} catch (err) {
				setErrorMessage(err.message)
				setIsError(true)
			}
			setIsDone(true)
			setIsLoading(false)
		}
		fetchPayload()
	}, [request])

	const apiFetch = (path, init = {}, opts = {}) => {
		setIsError(false)
		setErrorMessage('')
		setIsDone(false)
		setIsSuccess(false)
		setRequest({
			path,
			init,
			opts,
		})
	}

	const resetApiFetch = () => {
		setIsError(false)
		setErrorMessage('')
		setIsLoading(false)
		setIsDone(false)
		setIsSuccess(false)
		setRequest(null)
		setPayload(initialPayload)
	}

	return [
		{ payload, isLoading, isError, errorMessage, isDone, isSuccess },
		apiFetch,
		resetApiFetch,
	]
}

export const useApiFetch = (initialRequest = null, initialPayload = null) => {
	return useApiFetchBase(initialRequest, initialPayload, {
		isAuthenticated: true,
	})
}

export const usePublicApiFetch = (
	initialRequest = null,
	initialPayload = null
) => {
	return useApiFetchBase(initialRequest, initialPayload, {
		isAuthenticated: false,
	})
}

const apiFetchBase = async (path, init = {}, opts = {}) => {
	const url = pathToUrl(path)
	const token = opts.isAuthenticated ? getToken() : null
	const _init = filterInit(init, token)
	const result = await fetch(url, _init)
	if (!result.ok) {
		const errorText = await getErrorMessageFromResponse(result)
		const error = new Error(errorText)
		error.statusCode = result.status
		throw error
	}
	return await getBodyFromResponse(result)
}

export const publicApiFetch = async (path, init = {}, opts = {}) => {
	return await apiFetchBase(path, init, opts)
}

export const apiFetch = async (path, init = {}, opts = {}) => {
	const _opts = opts.isAuthenticated
		? opts
		: Object.assign({}, opts, { isAuthenticated: true })
	return await apiFetchBase(path, init, _opts)
}
